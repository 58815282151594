<template>
  <div>
    <h3>About</h3>
    <div class="text-sm text-gray-500 mb-4"></div>
    <div class="max-w-screen-sm">
      <p>
        Software Engineer, Hands-on Cloud Architect, Technical Founder.
        I'm currently building <a class=" font-bold" target="_blank" href="https://cyclic.sh">cyclic.sh</a>, a platform for building and deploying serverless applications. 
      </p>
      <p>
        I build virtually with AWS, Serverless, Node.js, TypeScript, Rust, Python, Vue, and more. I also build physical things with wood, metal, and concrete.
      </p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'About',
  props: {
    msg: String
  },
  data(){
    return{
    }
  },
  async mounted() {   
    },
  methods:{
    
  }
}
</script>

