<template>
  <div>
    <h3>Projects</h3>
    <div class="text-sm text-gray-500 mb-4">Few things I've been building</div>
    <div>
      <a
          href="https://cyclic.sh/"
          target="_blank"
          class="font-bold "
        >
          cyclic.sh - Serverless Web Applications PaaS 
        </a>
    </div>
    <div> 
      <a
          href="https://www.youtube.com/watch?v=uhvp8D3Bo9E&ab_channel=MichaelKorostelev"
          target="_blank"
          class=" font-bold "
        >
          Rabbit Crowd Library - Public Library of State Policies
        </a>
      </div>
      <div>
      <a
          href="https://www.blackknightinc.com/blog/seller-digital-platform-uses-ai-and-decisioning-to-help-correspondent-lenders-easily-originate-and-sell-loans/"
          target="_blank"
          class=" font-bold "
        >
          SellerDigital - Correspondent Lending Platform 
        </a>
      </div>
      <div>
      <a
          href="https://pdaps.org/"
          target="_blank"
          class=" font-bold "
        >
          PDAPS - Public Health Law Data Collection
        </a>
      </div>
      <div>
      <a
          href="https://www.youtube.com/watch?v=b5aF5kRBX2U&ab_channel=MichaelKorostelev"
          target="_blank"
          class=" font-bold "
        >
          Monqcle - Policy Survey Tool
        </a>
      </div>
      <!-- <div>
      <a
          href="https://www.youtube.com/watch?v=xd54wQRQ1Yk&ab_channel=MichaelKorostelev"
          target="_blank"
          class=" font-bold "
        >
          Gotcha - Crowd Sourced Crime Reporting App 
        </a>
      </div> -->
      <div>
      <a
          href="https://snowman.coldlambda.com/#"
          target="_blank"
          class=" font-bold "
        >
          Snowman - OpenAPI Spec Mock Server (Sample)
      </a>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Projects',
  props: {
    msg: String
  },
  data(){
    return{
    }
  },
  async mounted() {   
    },
  methods:{
    
  }
}
</script>

